import { render, staticRenderFns } from "./AddBlockModal.vue?vue&type=template&id=7ed6aae7&scoped=true&"
import script from "./AddBlockModal.vue?vue&type=script&lang=js&"
export * from "./AddBlockModal.vue?vue&type=script&lang=js&"
import style0 from "./AddBlockModal.vue?vue&type=style&index=0&id=7ed6aae7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed6aae7",
  null
  
)

export default component.exports