<template>
	<ZyroModal
		max-width="75vw"
		max-height="80vh"
		:is-loading="!template"
		class="modal-add-block"
		:title="$t('builder.addNewSectionModal.title')"
		:subtitle="$t('builder.addNewSectionModal.subtitle')"
		position="bottom"
		@close-modal="$store.dispatch(`gui/${CLOSE_MODAL}`)"
	>
		<div class="modal-add-block__container">
			<div class="modal-add-block__categories">
				<button
					class="modal-add-block__blank z-body-small"
					:title="$t('builder.addNewSectionModal.addBlankSection')"
					@click="addNewBlock()"
				>
					{{ $t('builder.addNewSectionModal.addBlankSection') }} +
				</button>
				<ZyroSeparator class="modal-add-block__separator" />
				<ZyroListSelector
					v-if="categories"
					:list-data="categories"
					:current.sync="currentCategoryId"
				/>
			</div>
			<div class="modal-add-block__block-list">
				<!-- Measure modal inner width, for preview blocks width calculation -->
				<div
					ref="measureModalWidth"
					class="measure-modal-width"
				/>
				<masonry
					v-if="template"
					:col="MASONRY_COL"
					:gutter="MASONRY_GUTTER"
					class="modal-add-block__blocks"
					:style="siteCSSVariables"
				>
					<ZyroBlockPreview
						v-for="(block, blockId) in blocks"
						:key="`${blockId}-block`"
						v-qa="`builder-addnewsectionmodal-block-${blockId}`"
						:block-id="blockId"
						:block="block"
						:components="components"
						:blocks="template.blocks"
						:actual-width="actualBlockWidth"
						:preview-width="previewBlockWidth"
						class="modal-add-block__block-container"
						@click="addNewBlock(blockId, block), selectedBlockId=blockId"
					/>
				</masonry>
			</div>
		</div>
		<!-- Confirm modals (ecommerce, blog, global footer) -->
		<Portal
			v-if="showEcommerceChoice || showBlogListChoice || showFooterGlobalChoice"
			to="app"
		>
			<ChooseBlockPlacementModal
				v-if="showEcommerceChoice"
				:primary-button-text="$t('builder.addEcommerceBlock.buttonAddPage')"
				:secondary-button-text=" $t('builder.addEcommerceBlock.buttonAddHere')"
				:title="$t('builder.addEcommerceBlock.title')"
				:subtitle="$t('builder.addEcommerceBlock.subtitle')"
				@close="showEcommerceChoice = false"
				@add-primary="addPage(ECOMMERCE_CAT_ID)"
				@add-secondary="addEcommerceBlock"
			>
				<template #header-image>
					<img src="@/assets/images/store-sample.png">
				</template>
			</ChooseBlockPlacementModal>
			<SystemDialogModal
				v-if="showFooterGlobalChoice"
				:primary-button-text="$t('builder.footer.modal.addToSingle')"
				:secondary-button-text="$t('builder.footer.modal.addToAll')"
				title="Add footer"
				primary-button-theme="plain"
				primary-button-margin="0 16px 0 auto"
				@close="showFooterGlobalChoice = false"
				@click-primary="addNewBlock(selectedBlockId, blocks[selectedBlockId], false)"
				@click-secondary="addNewBlock(selectedBlockId, blocks[selectedBlockId], false, 'footer')"
			>
				{{ $t('builder.footer.modal.description') }}
			</SystemDialogModal>
			<ChooseBlockPlacementModal
				v-if="showBlogListChoice"
				:primary-button-text="$t('builder.blog.chooseBlogListModal.buttonAddPage')"
				:secondary-button-text="$t('builder.blog.chooseBlogListModal.buttonAddHere')"
				:title="$t('builder.blog.chooseBlogListModal.title')"
				:subtitle="$t('builder.blog.chooseBlogListModal.subtitle')"
				@close="showBlogListChoice = false"
				@add-primary="addPage(BLOG_CAT_ID)"
				@add-secondary="addBlogList"
			>
				<template #header-image>
					<img src="@/assets/images/blog-sample.png">
				</template>
			</ChooseBlockPlacementModal>
		</Portal>
	</ZyroModal>
</template>

<script>
import { watch } from '@vue/composition-api';
import gsap from 'gsap';

import ZyroListSelector from '@/components/reusable-components/ZyroListSelector.vue';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { useAddBlockModal } from '@/use/useAddBlockModal';

import ChooseBlockPlacementModal from './ChooseBlockPlacementModal.vue';
import SystemDialogModal from './SystemDialogModal.vue';

const MASONRY_COL = 2;
const MASONRY_GUTTER = 20;

export default {
	components: {
		SystemDialogModal,
		ChooseBlockPlacementModal,
		ZyroListSelector,
	},
	setup(props, context) {
		const {
			template,
			categories,
			components,
			doesFooterExist,
			selectedBlockId,
			currentCategoryId,
			addNewBlock,
			blocks,
			addEcommerceBlock,
			showEcommerceChoice,
			showBlogListChoice,
			showFooterGlobalChoice,
			addPage,
			addBlogList,
			BLOG_CAT_ID,
			ECOMMERCE_CAT_ID,
			siteCSSVariables,
		} = useAddBlockModal(context);

		const setAnimationToPreviewBlocks = () => {
			gsap.from('.modal-add-block__block-container', {
				duration: 0.15,
				delay: 0.1,
				y: 150,
				ease: 'power1',
				stagger: 0.03,
				clearProps: 'transform',
			});
			gsap.to('.modal-add-block__block-container', {
				opacity: 1,
				delay: 0.15,
				duration: 0.1,
			});
		};

		// Preview block calculation
		watch(blocks, async (newValue) => {
			if (!newValue) {
				return;
			}

			setAnimationToPreviewBlocks();
		});

		return {
			template,
			categories,
			components,
			doesFooterExist,
			selectedBlockId,
			currentCategoryId,
			addNewBlock,
			blocks,
			addEcommerceBlock,
			showEcommerceChoice,
			showBlogListChoice,
			showFooterGlobalChoice,
			addPage,
			addBlogList,
			BLOG_CAT_ID,
			ECOMMERCE_CAT_ID,
			siteCSSVariables,
			MASONRY_COL,
			MASONRY_GUTTER,
			setAnimationToPreviewBlocks,
			CLOSE_MODAL,
		};
	},
	data() {
		return {
			previewBlockWidth: 0,
			actualBlockWidth: 0,
		};
	},
	mounted() {
		this.previewBlockWidth = (this.$refs.measureModalWidth.offsetWidth
		/ MASONRY_COL - MASONRY_GUTTER / MASONRY_COL);
		this.actualBlockWidth = window.innerWidth;

		this.setAnimationToPreviewBlocks();
	},
};
</script>

<style lang="scss" scoped>
/*
 * Outline is invisible on the left side due to overflow,
 * even with overflow-x set to visible;
 * add padding-left and negative margin-left
 * to container to offset that
*/

$hover-outline-width: 1px;

.modal-add-block {
	&__container {
		display: grid;
		grid-template-columns: 25% 75%;
		grid-gap: 16px;
		height: 100%;
	}

	&__blank {
		display: block;
		width: 100%;
		padding: 12px 16px;
		margin: 0;
		text-align: left;
		text-decoration: none;
		cursor: pointer;
		user-select: none;
		background-color: transparent;
		border-radius: $border-radius-small;
	}

	&__separator {
		margin: 8px 0;
	}

	&__block-list {
		height: 100%;
		padding-left: $hover-outline-width;
		margin-left: -$hover-outline-width;
		overflow: auto;
	}

	&__blocks {
		padding-top: 60px;
	}

	&__block-container {
		width: 100%;
		margin-bottom: 20px;
		overflow: hidden;
		pointer-events: auto;
		outline: 2px solid transparent;
		box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
		opacity: 0; // opacity is animated with gsap
		transition: all 0.2s;

		&:hover {
			cursor: pointer;
			outline: $hover-outline-width solid $grey-300;
			transform: translateY(-4px);
		}
	}

	&__categories {
		overflow: auto;
	}
}

.measure-modal-width {
	width: 100%;
}
</style>
