<template>
	<div class="list-selector">
		<template
			v-for="(listItem, id) in listData"
		>
			<ZyroSeparator
				v-if="listItem.type === 'separator'"
				:key="`${id}-separator`"
				class="list-selector__separator"
			/>
			<button
				v-else
				:key="`${id}-listItem`"
				v-qa="`builder-addnewsectionmodal-btn-${listItem.name}`"
				class="list-selector__item z-body-small"
				:class="{ 'is-current': current === id }"
				@click="setCurrent(id)"
			>
				{{ listItem.name }}
				<span
					v-if="listItem.hasNewBadge"
					class="list-selector__new-badge z-body-small z-body-small--strong"
				>
					NEW
				</span>
				<ZyroSvg
					v-if="listItem.icon"
					:name="listItem.icon"
				/>
			</button>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		listData: {
			type: Object,
			required: true,
			validator: (values) => Object.values(values).every((value) => value.name || value.type),
		},
		current: {
			type: String,
			default: '',
		},
	},
	beforeMount() {
		if (!this.listData[this.current]) {
			this.setCurrent(Object.keys(this.listData)[0]);
		}
	},
	methods: {
		setCurrent(id) {
			this.$emit('update:current', id);
		},
	},
};
</script>

<style lang="scss" scoped>
.list-selector {
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 12px 16px;
		margin: 0;
		text-align: left;
		text-decoration: none;
		user-select: none;
		background-color: transparent;
		border-radius: $border-radius-small;

		&.is-current,
		&:hover,
		&:active,
		&:focus {
			background-color: $primary-lighter;
		}

		&:not(.is-current) {
			cursor: pointer;
		}

		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}

	&__separator {
		margin: 8px 0;
	}

	&__new-badge {
		padding: 0 8px;
		color: $light;
		text-align: center;
		background-color: $secondary;
		border-radius: 100px;
	}
}
</style>
